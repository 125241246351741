import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// utils
import { axiosInstanceNew } from '../../utils/axios';
import { setProfileProducts, updateDisplayedUser } from './user';

export const createProduct = createAsyncThunk(
  'product/newProduct',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      let apiEndPoint = '';

      switch (data.category) {
        case 'fashion':
          apiEndPoint = '/api/client/product/fashion';
          break;
        case 'vehicle':
          apiEndPoint = '/api/client/product/vehicles';
          break;
        case 'vehicleParts':
          apiEndPoint = '/api/client/product/vehicle-parts';
          break;
        case 'realEstate':
          apiEndPoint = '/api/client/product/real-estate';
          break;
        case 'electronics':
          apiEndPoint = '/api/client/product/electronics';
          break;
        case 'mobileAndTablets':
          apiEndPoint = '/api/client/product/mobile-Tablets';
          break;
        case 'healthAndBeauty':
          apiEndPoint = '/api/client/product/health-beauty';
          break;
        case 'babyAndKids':
          apiEndPoint = '/api/client/product/babies-kids';
          break;
        case 'food':
          apiEndPoint = '/api/client/product/food';
          break;
        case 'gemstones':
          apiEndPoint = '/api/client/product/gemstones';
          break;
        default:
          break;
      }

      const res = await axiosInstanceNew.post(apiEndPoint, data);
      dispatch(updateDisplayedUser(res.data));
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const viewedBy = createAsyncThunk(
//   'product/viewedBy',
//   async (data, { dispatch, rejectWithValue }) => {
//     try {
//       const response = await axiosInstanceNew.post('/api/client/product/viewedBy', data);
//       dispatch(productSlice.actions.getProductSuccess(response.data.product));
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const uploadImgs = createAsyncThunk(
  'product/uploadimgs',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceNew.post('/api/client/product/uploadimgs', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteImgs = createAsyncThunk(
  'product/deleteimgs',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceNew.post('/api/client/product/deleteimg', data, {
        data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchProducts = createAsyncThunk(
  'product/searchProducts',
  async ({ queryParams, shouldReplace }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceNew(`/api/client/product/searchProducts?${queryParams}`);
      return { ...response.data, shouldReplace };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchByTitle = createAsyncThunk(
  'product/searchByTitle',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceNew.post('/api/client/product/titleSearch', {
        searchInput: data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchHomePageProductsByTitle = createAsyncThunk(
  'product/searchHomePageProductsByTitle',
  async (searchText, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceNew(`/api/client/product/homepage-search/${searchText}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const publishProducts = createAsyncThunk(
  'product/publishProducts',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstanceNew.post(`/api/client/product/publish`, data);
      dispatch(setProfileProducts(response.data.products));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrders = createAsyncThunk(
  'product/fetch-orders',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceNew(`/api/client/product/fetch-orders/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createReview = createAsyncThunk(
  'user/createReview',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstanceNew.post('/api/client/review/create-review', data);
      dispatch(productSlice.actions.getProductSuccess(res.data));
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateReview = createAsyncThunk(
  'user/updateReview',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstanceNew.post('/api/client/review/update-review', data);
      dispatch(productSlice.actions.getProductSuccess(res.data));
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteReview = createAsyncThunk(
  'user/deleteReview',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstanceNew.post('/api/client/review/delete-review', data);
      dispatch(productSlice.actions.getProductSuccess(res.data));
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const likeReview = createAsyncThunk(
  'user/likeReview',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstanceNew.post('/api/client/review/like-review', data);
      dispatch(productSlice.actions.getProductSuccess(res.data));
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const dislikeReview = createAsyncThunk(
  'user/dislikeReview',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstanceNew.post('/api/client/review/dislike-review', data);
      dispatch(productSlice.actions.getProductSuccess(res.data));
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export function getProducts() {
  return async (dispatch) => {
    dispatch(productSlice.actions.startLoading());
    try {
      const response = await axiosInstanceNew.get('/api/client/product/fetchProducts');
      dispatch(productSlice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(productSlice.actions.hasError(error?.response?.data?.message));
    }
  };
}

export const getHomePageProducts = createAsyncThunk(
  'product/homeProducts',
  async (location, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceNew.post(
        '/api/client/product/fetchHomeProducts',
        location
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProductToEdit = createAsyncThunk(
  'products/edit-user-product',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstanceNew.post('/api/client/product/edit-user-product', data);

      dispatch(productSlice.actions.getProductToEditSuccess(response.data));

      return response.data.product;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const getProduct = createAsyncThunk(
  'products/getProduct',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstanceNew.post('/api/client/product/fetchProduct', data);
      if (data.isEdit) {
        dispatch(productSlice.actions.getProductToEditSuccess(response.data));
      }
      dispatch(productSlice.actions.getProductSuccess(response.data));

      return response.data.product;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const unPublishProduct = createAsyncThunk(
  'products/unpublish-product',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstanceNew.post('/api/client/product/unpublish-product', data);

      dispatch(setProfileProducts(response.data.products));
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message);
    }
  }
);

const initialState = {
  isLoading: true,
  error: null,
  products: [],
  productsCount: null,
  subcategoryCounts: null,
  categoryCounts: null,
  regionCounts: null,
  hasMore: true,
  currentProduct: null,
  myProducts: [],
  userProducts: [],
  editProduct: null,
  orders: [],
  featuredProducts: [],
  newArrivalsProducts: [],
  nearestProducts: [],
  offline: false,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET MY PRODUCTS
    getMyProductsSuccess(state, action) {
      state.isLoading = false;
      state.myProducts = action.payload;
    },

    // GET USER PRODUCTS
    getUserProductsSuccess(state, action) {
      state.isLoading = false;
      state.userProducts = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.currentProduct = action.payload.product;
      if (action.payload.offline) {
        state.offline = action.payload.offline;
      }
    },

    // GET PRODUCT TO EDIT
    getProductToEditSuccess(state, action) {
      state.isLoading = false;
      state.editProduct = action.payload.product;
    },

    // RESET PRODUCT
    resetProduct(state) {
      state.isLoading = true;
      state.currentProduct = null;
      state.offline = false;
    },
    // RESET PRODUCTS
    resetProducts(state) {
      state.isLoading = true;
      state.products = null;
      state.hasMore = true;
      state.subcategoryCounts = null;
      state.productsCount = null;
      state.regionCounts = null;
      state.categoryCounts = null;
      state.offline = false;
    },
    // RESET EDIT PRODUCT
    resetEditProduct(state) {
      state.isLoading = true;
      state.editProduct = null;
      state.offline = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.products = action.payload.products;
        state.categoryCounts = action.payload.categoryCounts;
        state.subcategoryCounts = action.payload.subcategoryCounts;
        state.productsCount = action.payload.productsCount;
        state.regionCounts = action.payload.regionCounts;
        state.error = null;
        state.hasMore = action.payload.hasMore;
      })
      .addCase(searchProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      })
      .addCase(searchHomePageProductsByTitle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchHomePageProductsByTitle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.products = action.payload.products;
        state.categoryCounts = action.payload.categoryCounts;
        state.productsCount = action.payload.productsCount;
        state.regionCounts = action.payload.regionCounts;
        state.error = null;
        state.hasMore = action.payload.hasMore;
      })
      .addCase(searchHomePageProductsByTitle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      })
      .addCase(getHomePageProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHomePageProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.featuredProducts = action.payload.featuredProducts;
        state.newArrivalsProducts = action.payload.newArrivalsProducts;
        state.nearestProducts = action.payload.nearestProducts;
        state.error = null;
      })
      .addCase(getHomePageProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      })
      .addCase(fetchOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orders = action.payload.orders;
        state.error = null;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      });
  },
});

// Reducer
export default productSlice.reducer;

// Actions
export const { getProductSuccess, resetProduct, resetProducts, resetEditProduct } =
  productSlice.actions;
